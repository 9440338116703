<template>
  <div class="detail-box detail">
    <el-row class="title-box">
      <el-col class="title">
        <span>模版详情页</span>
      </el-col>
      <el-col :span="18" class="time-col">
        <span class="publish">2023-03-29</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    <!--发布时间：-->
        <span class="publish">来源：自然资源</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="publish">作者：XXXX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>浏览次数：0</span>
      </el-col>
      <el-col :span="6" class="font-col">
        <span class="font">
          字体：【
          <a @click="big()" class="big">大</a>
          <a @click="medium()" class="medium">中</a>
          <a @click="small()" class="small">小</a>】
        </span>
      </el-col>
    </el-row>

    <el-row :class="textSize" class="text-box">
      <div class="con-box">
         <p class="p-title">保护级别</p>
         <p class="p-text">
           一般全长1-2米，幼体亦长300毫米以上。头吻较长，鼻孔近吻端;耳孔与眼径等大;舌长，前端分叉，可缩入舌鞘内。
           四肢强壮，指、趾具锐爪，其背面也有小黄斑，故称"五爪金龙"。体腹面为黄色。幼年巨蜥具黑白相间粗大环纹 。吻
           较长，吻背正中呈一凹槽;头窄长，头长约为头宽的 2 倍，略呈三角形，吻端圆。头与颈之间有一明显的弧形压痕，此
           弧中央向前微凸，两侧后端相当于耳孔上角水平。鼻孔大，扁圆形;躯干壮实而略扁平。四肢粗壮，爪长而坚硬。尾侧
           扁如鞭，尾基较粗，往后渐侧扁而细，唯尾腹较宽而略圆，尾背则由并列 2 行鳞片形成棱脊，尾末端略圆钝，数枚小
           鳞中央围一锥鳞，坚硬而上翘。背面黑褐色为主，杂有稀疏黄点，颈侧、体侧与尾侧的黄点较密集。尾后半部的黑褐色较
           深，显出7条宽黑纹。腹面黄白色，头腹与颈腹各 4 条及 5 条由背侧延伸来的黑纹在腹中线相遇。吻、颊与上唇缘棕
           褐色。
         </p>
        <img class="bg" src="../../../assets/images/thum_plant.png"/>
      </div>
<!--      <el-col class="QR-col">-->
<!--        <p><span class="tips">扫描二维码从微信进入</span></p>-->
<!--        <div ref="qr" style="margin-left: auto; margin-right: auto; width: 140px"></div>-->
<!--      </el-col>-->
      <ul class="operate">
        <li>
          <el-popover
              :content="collectText"
              placement="left"
              trigger="hover">
            <span slot="reference" :class="{'collect': isCollect}" class="star" @click="handleCollect"></span>
          </el-popover>
        </li>
        <li>
          <el-popover
              placement="left-start"
              trigger="hover"
              width="240">
            <div class="share-cont">
              <p class="title"><span>分享至</span></p>
              <ul class="im">
                <share :config="share_config"></share>
              </ul>
            </div>
            <span slot="reference" class="share"></span>
          </el-popover>
        </li>
      </ul>
    </el-row>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "articleShow",
  components: {
    QRCode
  },
  data() {
    return {
      textSize: '',
      isCollect: false,
      collectText: '收藏',
      share_config: {
        url: window.location.href,
        sites: ['weibo', 'qq', 'wechat', 'qzone']
      },

    }
  },
  mounted() {

  },
  methods: {
    /**
     * 收藏操作
     */
    handleCollect() {

    },
    //变大
    big() {
      this.textSize = 'big'
    },
    medium() {
      this.textSize = 'medium'
    },
    small() {
      this.textSize = 'small'
    },

  }
}
</script>

<style scoped lang="scss">
.detail-box{
  width: 1200px;
  margin: 0 auto;
  .con-box{
    display: flex;
    flex-direction: column;
    .p-title{
      font-weight: 550;
    }
    p{
      text-indent: 30px;
    }
    img{
      width: 450px;
      height: 400px;
      margin: 6px auto;
    }
  }
  ::v-deep .con-box span {
    font-size: inherit !important;
  }
}
.detail {
  .step {
    padding: 12px 0px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .title-box{

    .title{
      text-align:center;
      padding:30px 0;
      border-bottom: 1px dashed #d1d1d1;
      span{
        line-height:63px;
        font-size:32px;
        color:#000;
      }
    }
    .time-col,.font-col{
      line-height:58px;
      font-size:16px;
      color:#666;
      margin-bottom:20px;
    }
    .time-col{
      text-align:left;
      display: flex;
      .publish{
        display: inline-block;
      }
    }
    .font-col{
      text-align:right;
      .font{
        a{
          margin:0 5px;
        }
      }
      .line{
        margin:0 20px;
        color:#666;
      }
    }
  }
  .text-box{
    font-size:16px;
    color:#666;
    line-height:34px;
    .author-col{
      text-align:right;
      margin-top:30px;
      .author{
        margin-left:20px;
      }
    }
    .QR-col{
      text-align:center;
      margin:40px 0;
      .tips{
        font-size:14px;
        color:#999;
      }
    }
    .operate{
      position:absolute;
      top:0;
      right:-68px;
      li{
        .star,.share{
          display:block;
          height:48px;
          width:48px;
          cursor:pointer;
          border-radius:4px;
          background:center no-repeat #40BFFF;
        }
        .star{
          background-image:url(../../../assets/images/icon_collec.png)
        }
        .collect{
          background-image:url(../../../assets/images/icon_collec_hov.png)
        }
        .share{
          background-image:url(../../../assets/images/icon_share.png)
        }
        .share:hover{
          background-color:#039BE5;
        }
      }
      li:first-child{
        margin-bottom:8px;
      }

    }
  }
  .text-box.big{
    font-size:18px;
  }
  .text-box.medium{
    font-size:16px;
  }
  .text-box.small{
    font-size:14px;
  }
  .text-col{
    text-indent:32px;
    text-align:justify;
  }
  .active {
    color: rgba(0, 120, 255, 1);
  }
}
.share-cont{
  .title,.QR,.wechat{
    text-align:center;
  }
  .title{
    font-size:16px;
    color:#000;
    margin-top:10px;
  }
  .QR{
    margin:16px 0 5px;
    img{
      width:100px;
      height:100px;
    }
  }
  .wechat{
    font-size:14px;
    color:#999;
    margin-bottom:15px;
  }
  .im{
    display:flex;
    li{
      flex:1;
      height:20px;
      cursor:pointer;
      padding-top:50px;
      text-align:center;
      background:center 5px no-repeat;
    }

  }
}


</style>
